import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { routes } from "../../config/routes";
import Button from "../Button";
import NavLink from "./NavLink";

const imgLogo = require("../../assets/image/logo.png");

const NavBar = () => {
  const location = useLocation();

  const links = [
    {
      path: routes.home,
      text: "Inicio",
    },
    {
      path: routes.clients,
      text: "Clientes",
    },
    {
      path: routes.contact,
      text: "Contacto",
    },
  ];

  useEffect(() => {
    if (location.hash) {
      const element = document.querySelector(
        `[data-nav=${location.hash.slice(1)}]`
      );

      element?.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "start",
      });
    }
  }, [location.hash]);

  return (
    <nav className="bg-white border-gray-200 px-2 sm:px-4 py-2.5 rounded dark:bg-gray-800 fixed w-full z-40">
      <div className="container flex flex-wrap justify-between items-center mx-auto">
        <a href="/" className="flex items-center">
          <img src={imgLogo} className="mr-3 w-[250px]" alt="TuFactura Logo" />
        </a>

        {/* Mobile */}
        <button
          data-collapse-toggle="mobile-menu"
          type="button"
          className="inline-flex items-center p-2 ml-3 text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
          aria-controls="mobile-menu"
          aria-expanded="false"
        >
          <span className="sr-only">Abrir menu principal</span>
          <svg
            className="w-6 h-6"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
              clip-rule="evenodd"
            ></path>
          </svg>
          <svg
            className="hidden w-6 h-6"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
              clip-rule="evenodd"
            ></path>
          </svg>
        </button>

        {/* Nav */}
        <div className="hidden w-full md:block md:w-auto" id="mobile-menu">
          <ul className="flex flex-col items-center mt-4 md:flex-row md:space-x-4 md:mt-0 md:text-sm md:font-medium">
            {links.map(({ path, text }) => (
              <NavLink {...{ path, text }} />
            ))}

            <li>
              <Button className="rounded-3xl" size="lg">
                Acceder
              </Button>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default NavBar;
