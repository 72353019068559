import Button from "../components/Button";
import { FaEnvelopeOpen, FaFileDownload } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { routes } from "../config/routes";

const imgComputer = require("../assets/image/computer.png");
const imgSeniatBW = require("../assets/image/seniat-bw.png");

const imgChart = require("../assets/image/home-chart.png");
const imgHands = require("../assets/image/home-hands.png");
const imgFiles = require("../assets/image/home-files.png");

const imgSignature = require("../assets/image/service-signature.png");
const imgEmail = require("../assets/image/service-email.png");
const imgWeb = require("../assets/image/service-web.png");
const imgSMS = require("../assets/image/service-sms.png");
const imgIVR = require("../assets/image/service-ivr.png");
const imgIndicators = require("../assets/image/service-indicators.png");

const imgUniversitas = require("../assets/image/universitas-1.png");
const imgSanitas = require("../assets/image/sanitas-1.png");
const imgSegurosCaracas = require("../assets/image/seguroscaracas.png");
const imgMapfre = require("../assets/image/mapfre.png");
const imgSegurosLaFe = require("../assets/image/seguroslafe-1.png");
const imgStarSeguros = require("../assets/image/starseguros-1.png");
const imgNetuno = require("../assets/image/netuno-1.png");
const imgCredicard = require("../assets/image/credicard-1.png");

const fileGaceta = require("../assets/files/Gaceta-Oficial-40488-Providencia-0032-SENIAT.pdf");

const stats = [
  {
    logo: imgChart,
    title: "Compañía",
    subtitle: "En Gestión e Informática Documental",
  },
  {
    logo: imgHands,
    title: "Clientes",
    subtitle: "En todos los sectores tanto públicos como privados",
  },
  {
    logo: imgFiles,
    title: "Documentos",
    subtitle: (
      <span>
        Procesados y almacenados con nuestra aplicación de <br />
        Gestión Documental
      </span>
    ),
  },
];

const services = [
  { logo: imgSignature, title: "Documentos con firma electrónica" },
  { logo: imgEmail, title: "Envío masivo de correos y botón de pago" },
  { logo: imgWeb, title: "Visor Web" },
  { logo: imgSMS, title: "Envío de notificaciones por SMS" },
  { logo: imgIVR, title: "IVR​" },
  { logo: imgIndicators, title: "Indicadores de gestión​" },
];

const clients = [
  {
    logo: imgUniversitas,
    path: "universitas",
  },
  {
    logo: imgSanitas,
    path: "sanitas",
  },
  {
    logo: imgSegurosCaracas,
    path: "",
  },
  {
    logo: imgMapfre,
    path: "mapfre",
  },
  {
    logo: imgSegurosLaFe,
    path: "seguroslafe",
  },
  {
    logo: imgStarSeguros,
    path: "estarseguros",
  },
  {
    logo: imgNetuno,
    path: "netuno",
  },
  {
    logo: imgCredicard,
    path: "credicard",
  },
];

export const HomePage = () => {
  const navigate = useNavigate();

  return (
    <div>
      <div className="bg-banner-pattern flex flex-col md:flex-row px-8 md:px-32 pb-32 lg:pb-8 pt-32 mb-52 lg:mb-0">
        <div className="flex flex-col py-24 space-y-10 flex-1">
          <div>
            <p className="text-4xl md:text-6xl text-white font-bold">
              Facturación Digital
            </p>
            <div className="h-1.5 bg-white w-[200px] mt-6"></div>
          </div>

          <p className="text-white text-xl">
            Facturar nunca había sido tan fácil y rápido
          </p>

          <Button
            className="w-[250px]"
            size="lg"
            color="secondary"
            onClick={() => navigate(routes.contact)}
          >
            ¡Quiero saber más!
          </Button>
        </div>

        <div className="absolute left-0 right-0 mx-auto flex items-center -bottom-36 w-80 lg:w-auto lg:static lg:mx-0">
          <img src={imgComputer} alt="computadora" />
        </div>
      </div>

      <div>
        <p className="w-full text-center text-5xl font-medium text-gray-800 my-16">
          ¿Qué Es La Facturación Digital?
        </p>
        <div className="grid grid-cols-1 md:grid-cols-2">
          <div className="px-16 lg:px-32 pb-16 text-xl text-gray-800 space-y-8 font-light">
            <p>
              La presente Providencia Administrativa{" "}
              <span className="font-semibold">N° SNAT/2014/0032</span>: tiene
              por objeto regular la utilización de medios distintos para la
              emisión de facturas, notas de débito, notas de crédito, órdenes de
              entrega o guías de despacho y permitir la autorización de Imprenta
              Digitales.
            </p>
            <p className="font-semibold">
              Providencia SNAT/ 2014/ 0032 en su artículo 19, Capitulo IV
              dispone:
            </p>
            <p>
              La imprenta digital es realizada por sociedades mercantiles o
              sociedades cooperativas domiciliadas en el país cuya actividad
              principal sea la asignación de número de control de las facturas y
              otros documentos en virtud de la autorización otorgada por el{" "}
              <span className="font-semibold">SENIAT</span>.
            </p>
            <div>
              <p className="mb-4">
                En concordancia con el artículo 2 de la UT supra providencia
                podrán optar por la utilización de medios distintos para la
                emisión de facturas y otros documentos a los que se refiere esta
                Providencia los sujetos pasivos personas jurídicas públicas o
                privadas que presten los siguientes servicios masivos:
              </p>

              <ol className="list-decimal space-y-2 ml-8">
                <li>Agencias de viaje y similares</li>
                <li>Agua potable</li>
                <li>Aseguradoras</li>
                <li>Aseo urbano</li>
                <li>Courier de cargo y mensajería</li>
                <li>Difusión de televisión por suscripción</li>
                <li>Electricidad</li>
                <li>
                  Emisión de vales cupones tickets y tarjetas electrónicas de
                  beneficios sociales de alimentacion
                </li>
                <li>Gas doméstico</li>
                <li>Internet</li>
                <li>Líneas aéras</li>
                <li>Telefónia básica</li>
                <li>Telefonía móvil</li>
              </ol>

              <img src={imgSeniatBW} alt="SENIAT Logo" className="mt-4" />
            </div>
          </div>
          <div className="bg-home-1 bg-no-repeat bg-cover bg-center hidden md:block"></div>
        </div>
      </div>

      <div className="bg-banner-pattern flex px-4 py-8 justify-center items-center">
        <p className="mr-4 md:mr-32 text-3xl font-medium text-white">
          Descarga Acá La Gaceta Oficial
        </p>
        <a href={fileGaceta} target="_blank" role="button" rel="noreferrer">
          <Button color="secondary" size="lg">
            <FaFileDownload className="mr-2" />
            Descargar Gaceta
          </Button>
        </a>
      </div>

      <div className="bg-gray-800 py-16 grid grid-cols-1 md:grid-cols-3 gap-y-16">
        {stats.map(({ logo, title, subtitle }) => (
          <div className="text-white text-center space-y-6">
            <img src={logo} alt="logo" className="w-[60%] mx-auto"></img>

            <div className="text-3xl font-medium">{title}</div>
            <div className="text-lg">{subtitle}</div>
          </div>
        ))}
      </div>

      <div>
        <div className="grid grid-cols-1 md:grid-cols-2">
          <div className="bg-home-2 bg-no-repeat bg-cover bg-center hidden md:block"></div>

          <div className="px-16 lg:px-32 py-16 text-xl text-gray-800 space-y-8 font-light">
            <p>
              Olvídate de las facturas en papel y entra en la era digital. Con
              nuestra Gestión Documental digitaliza tus documentos fiscales,
              formas libres y hazlos llegar a tus clientes en cuestión de
              segundos.
            </p>
            <p>
              Lo más importante es que todos tus documentos fiscales tendrán
              validez ante el SENIAT.
            </p>
            <p className="font-semibold">
              La suite cuenta con los siguientes servicios:
            </p>
            <ul className="list-disc pl-8 space-y-4">
              <li>
                Nuestra Suite Documental generará los formularios asignándoles,
                según la forma seleccionada, el número de factura, formas libre,
                asignándoles el número de control de manera incremental,
                llevando un control por cliente, empresa, series y tipos de
                documentos.
              </li>
              <li>
                Se identificará con el pie de imprenta los números de control
                asignado y la fecha de emisión de las mismas.
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="bg-banner-pattern py-8 bg-no-repeat bg-cover">
        <p className="w-full text-center text-5xl font-medium text-white my-16">
          Servicios De La Suite Documental
        </p>

        <div className="grid grid-cols-2 md:grid-cols-3 gap-y-12 bg-white bg-opacity-50">
          {services.map(({ logo, title }) => (
            <div className="text-gray-700 text-center py-8">
              <img
                src={logo}
                alt="logo"
                className="mx-auto mb-4 w-[80px] md:w-[100px] lg:w-auto"
              />
              <p className="text-xl">{title}</p>
            </div>
          ))}
        </div>
      </div>

      <div className="bg-gray-800 py-12 flex flex-col" data-nav="clientes">
        <p className="w-full text-center text-5xl font-medium text-white mb-8">
          Nuestros clientes
        </p>

        <p className="text-xl text-center text-white mb-12">
          Click en el logo de tu empresa para ingresar al visor
        </p>

        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-y-12 gap-x-12 mx-auto">
          {clients.map(({ logo, path }) => (
            <a
              className="text-center p-8 hover:bg-gray-900 flex items-center"
              href={`https://unidigital.global/${path}`}
            >
              <img
                src={logo}
                alt="logo"
                height="108px"
                className="mx-auto w-[200px] h-auto"
              />
            </a>
          ))}
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2" data-nav="contactar">
        <div className="bg-banner-pattern bg-cover bg-center px-4 md:px-16">
          <p className="w-full text-center text-5xl font-medium text-white my-16">
            ¿Estás interesado en saber más?
          </p>

          <p className="w-full text-xl text-white mb-4">
            Estaremos encantados de atenderte, te responderemos a la brevedad
            posible.
          </p>

          <p className="w-full text-xl text-white mb-8">
            ¿Generas mas de 10.000 Facturas u Otros Documentos Fiscales
            Mensuales?
          </p>

          <div className="flex flex-col space-y-4 max-w-2xl ">
            <input placeholder="Nombre"></input>
            <input placeholder="Email"></input>
            <input placeholder="Teléfono de Contacto"></input>
            <textarea placeholder="Mensaje"></textarea>
            <Button color="secondary" className="w-[200px]" size="lg">
              Enviar
            </Button>
          </div>

          <div className="my-8">
            <p className="text-white text-lg mb-4 font-medium">
              O contáctanos vía correo:
            </p>
            <Button color="secondary" className="w-auto" size="lg">
              <FaEnvelopeOpen className="mr-4" />
              Univentas@unidigital.global
            </Button>
          </div>
        </div>
        <div className="bg-home-3 bg-no-repeat bg-cover bg-center hidden md:block"></div>
      </div>
    </div>
  );
};
