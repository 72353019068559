import { FC, PropsWithChildren } from "react";
import { Routes } from "react-router";

interface Props extends PropsWithChildren<{}> {}

const AppRoutes: FC<Props> = ({ children }) => {
  return <Routes>{children}</Routes>;
};

export default AppRoutes;
