import React, { FC } from "react";
import { useLocation } from "react-router-dom";
import { classNames, tw } from "../../helpers/classNames";

interface Props {
  text: string;
  path: string;
}

const NavLink: FC<Props> = ({ text, path }) => {
  const location = useLocation();

  const isActive = location.pathname === path;

  const classes = classNames(
    tw`block 
    py-2 pr-4 pl-3 md:pr-0 md:pl-0 min-w-[150px] text-center
    border-b-[3px] border-b-transparent hover:border-b-primary-500
    text-white  md:text-gray-800 text-lg font-light
    bg-primary-500 md:bg-transparent`,
    {
      [tw`border-b-primary-500`]: isActive,
    }
  );

  return (
    <li>
      <a href={path} className={classes} aria-current="page">
        {text}
      </a>
    </li>
  );
};

export default NavLink;
