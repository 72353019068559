import React from "react";

const imgLogoBW = require("../../assets/image/logo-bw.png");

const Footer = () => {
  return (
    <div className="bg-gray-800 text-center py-16">
      <img src={imgLogoBW} alt="logo" className="mx-auto" />
      <div className="text-white text-xl font-light space-y-4">
        <p>Copyright © 2012 Corporación Unidigital 1220, C.A.</p>
        <p>J-40148330-5</p>
        <p> Todos los derechos reservados.</p>
      </div>
    </div>
  );
};

export default Footer;
