// import ClientsPage from "../pages/ClientsPage";
// import ContactPage from "../pages/ContactPage";
import { HomePage } from "../pages/HomePage";
import Seniat from "../pages/Seniat";

interface DirRoute {
  path: string;
  element: JSX.Element;
}

export const routes = {
  clients: "/#clientes",
  contact: "/#contactar",
  home: "/",
  seniat: "/seniat/cb368659-3f1b-466b-92ca-546343993912",
};

export const publicDir: DirRoute[] = [
  { path: routes.home, element: <HomePage /> },
  { path: routes.seniat, element: <Seniat /> },
  // { path: routes.contact, element: <ContactPage /> },
  // { path: routes.clients, element: <ClientsPage /> },
];
