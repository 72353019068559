import { DetailedHTMLProps, FC, useMemo } from "react";
import { classNames, tw } from "../helpers/classNames";

interface Props
  extends Omit<
    DetailedHTMLProps<
      React.ButtonHTMLAttributes<HTMLButtonElement>,
      HTMLButtonElement
    >,
    "size"
  > {
  variant?: "solid" | "ghost" | "outline" | "link";
  size?: "xsm" | "sm" | "md" | "lg";
  color?: "primary" | "secondary" | "info" | "error";
}

// Size

const sizes: { [key in NonNullable<Props["size"]>]: string } = {
  xsm: tw`px-1 py-0`,
  sm: tw`px-2 py-1`,
  md: tw`px-4 py-2`,
  lg: tw`px-5 py-3`,
};

// Component

const Button: FC<Props> = ({
  className,
  variant = "solid",
  children,
  color = "primary",
  size = "md",
  ...props
}) => {
  const variants: { [key in NonNullable<Props["variant"]>]: string } =
    useMemo(() => {
      if (color === "secondary") {
        return {
          solid: tw`bg-secondary-700 border-secondary-700 hover:bg-secondary-800 hover:border-secondary-800 text-white`,
          outline: tw`border-secondary-700 hover:bg-secondary-700 hover:text-white text-secondary-700`,
          ghost: tw`border-transparent hover:bg-secondary-300`,
          link: tw`border-secondary-700 hover:bg-secondary-300 hover:text-white`,
        };
      }

      return {
        solid: tw`bg-primary-500 border-primary-500 hover:bg-primary-700 hover:border-primary-700 text-white`,
        outline: tw`border-primary-500 hover:bg-primary-500 hover:text-white text-primary-500`,
        ghost: tw`border-transparent hover:bg-primary-300`,
        link: tw`border-primary-500 hover:bg-primary-300 hover:text-white`,
      };
    }, [color]);

  const buttonClasses = classNames(
    tw`btn border-2 border-solid rounded-3xl transition-colors uppercase inline-flex items-center justify-center space-x-4`,
    variants[variant],
    sizes[size],
    className
  );

  return (
    <button className={buttonClasses} {...props}>
      {children}
    </button>
  );
};

export default Button;
