import { BrowserRouter, Route } from "react-router-dom";
import AppRoutes from "./components/layout/AppRoutes";
import Footer from "./components/layout/Footer";
import NavBar from "./components/navbar/NavBar";
import { publicDir } from "./config/routes";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <NavBar />

        <main>
          <AppRoutes>
            {publicDir.map((dir) => (
              <Route {...dir} />
            ))}
          </AppRoutes>
        </main>

        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
