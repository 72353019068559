import imgClientGross from "../assets/image/ImprentaDigital/gross.png";
import imgClientLatinPagos from "../assets/image/ImprentaDigital/latinpagos.png";
import imgClientMercantilSeguros from "../assets/image/ImprentaDigital/mercantilseguros.png";
import imgClientPagoDirecto from "../assets/image/ImprentaDigital/pago_directo.png";
import imgClientPedidosYA from "../assets/image/ImprentaDigital/pedidosya.png";
import imgClientRidery from "../assets/image/ImprentaDigital/ridery_logo.png";
import imgClientTicketMundo from "../assets/image/ImprentaDigital/ticketmundo.png";
import imgClientUnidigital from "../assets/image/ImprentaDigital/unidigital.png";
import imgClientVnet from "../assets/image/ImprentaDigital/vnet.png";
import imgClientYummy from "../assets/image/ImprentaDigital/yummy.png";
import imgClientGoliiive from "../assets/image/ImprentaDigital/goliiive.jpg";
import imgClientSambilOnline from "../assets/image/ImprentaDigital/sambilOnline.png";
import imgClient1000pagos from "../assets/image/ImprentaDigital/1000pagos.jpg";
import imgClientLawawa from "../assets/image/ImprentaDigital/lawawa.jpg";
import imgClientUniversitas from "../assets/image/ImprentaDigital/segurosuniversitas.png";
import imgClientStarSeguros from "../assets/image/ImprentaDigital/starseguros.png";
import imgClientSegurosLaFe from "../assets/image/ImprentaDigital/seguroslafe.png";
import imgClientNetuno from "../assets/image/ImprentaDigital/netuno.png";
import imgClientSegurosCaracas from "../assets/image/ImprentaDigital/seguroscaracas.png";
import imgClientMapfre from "../assets/image/ImprentaDigital/mapfre.png";
import imgClientUniticket from "../assets/image/ImprentaDigital/uniticketGrupoUnico.jpg";
import imgClientCredicard from "../assets/image/ImprentaDigital/credicard.png";

const linkPublic = "https://www.unidigital.global/digitalinvoice";

const ImprentaDigital = [
  {
    logo: imgClient1000pagos,
    link: linkPublic,
    razonSocial: "Inversiones Gross, C.A.",
  },
  {
    logo: imgClientLatinPagos,
    link: linkPublic,
    razonSocial: "Servicios Latin Pagos 2021 C.A.",
  },
  // {
  //   logo: imgClientMiBanco,
  //   link: linkPublic,
  // },
  {
    logo: imgClientMercantilSeguros,
    link: linkPublic,
    razonSocial: "Mercantil Seguros C.A.",
  },
  {
    logo: imgClientPagoDirecto,
    link: linkPublic,
    razonSocial: "Corporación Paytech C.A.",
  },
  {
    logo: imgClientPedidosYA,
    link: linkPublic,
    razonSocial: "Delivery Hero Panama S.A. Sucursal Venezuela S.A.",
  },
  {
    logo: imgClientRidery,
    link: linkPublic,
    razonSocial: "Desarrollos Tecnologicos Ridery C.A.",
  },
  {
    logo: imgClientTicketMundo,
    link: linkPublic,
    razonSocial: "Ticketmundo C.A.",
  },
  {
    logo: imgClientUnidigital,
    link: linkPublic,
    razonSocial: "Corporación Unidigital 1220 C.A.",
  },
  {
    logo: imgClientVnet,
    link: linkPublic,
    razonSocial: "Corporación VNET C.A.",
  },
  {
    logo: imgClientYummy,
    link: linkPublic,
    razonSocial: "Pideyummy S.A.",
  },
  {
    logo: imgClientGoliiive,
    link: linkPublic,
    razonSocial: "Grupo Super Boletos 101 C.A.",
  },
  {
    logo: imgClientSambilOnline,
    link: linkPublic,
    razonSocial: "Sambil Online C.A.",
  },
  {
    logo: imgClientCredicard,
    link: "http://www.unidigital.global/credicard",
    razonSocial: "Consorcio Credicard C.A.",
  },
  {
    logo: imgClientUniversitas,
    link: "https://www.unidigital.global/universitas",
    razonSocial: "Seguros Universitas, C.A.",
  },
  {
    logo: imgClientStarSeguros,
    link: "https://www.unidigital.global/estarseguros",
    razonSocial: "Estar Seguros S.A.",
  },
  {
    logo: imgClientSegurosLaFe,
    link: linkPublic,
    razonSocial: "Seguros la Fe, C.A",
  },
  {
    logo: imgClientNetuno,
    link: linkPublic,
    razonSocial: "Netuno C.A.",
  },
  {
    logo: imgClientNetuno,
    link: linkPublic,
    razonSocial: "Veninfotel Comunicaciones, C.A.",
  },
  {
    logo: imgClientSegurosCaracas,
    link: "http://www.unidigital.global/seguroscaracas",
    razonSocial: "Seguros Caracas C.A.",
  },
  {
    logo: imgClientMapfre,
    link: "https://www.unidigital.global/mapfre/",
    razonSocial: "Mapfre la seguridad C.A. de Seguros",
  },
  {
    logo: imgClientUniticket,
    link: "https://www.unidigital.global/uniticket",
    razonSocial: "Grupo Unico C.A.",
  },
  {
    logo: imgClientLawawa,
    link: linkPublic,
    razonSocial: "La Wawa Venezuela 2021 C.A.",
  },
];
const Seniat = () => {
  return (
    <div className="">
      <p className="text-primary-500 text-4xl font-semibold mb-12 pb-16 pt-36 text-center ">
        Imprenta Digital
      </p>
      <div className="grid grid-cols-2 md:grid-cols-4 container mx-auto gap-y-8 pb-16">
        {ImprentaDigital.map(({ logo, link, razonSocial }) => (
          <div className="row row-cols-2 m-auto">
            <a
              href={link}
              key={link}
              target="_blank"
              rel="noreferrer"
              className="c_container transition-all flex items-center justify-center hover:scale-125"
            >
              <img
                src={logo}
                alt="logo"
                className="grayscale w-[100px] mh-[100px] hover:grayscale-0"
              />
            </a>
            <div className="m-3 ">{razonSocial}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Seniat;
